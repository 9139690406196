
export const displayRoles = (roles=[])=>{
  const roleOrder = ['schoolAdmin', 'teacher', 'counselor','administrator']; // Define the preferred order of roles

  const sortedRoles = roles
    .filter(role => roleOrder.includes(role)) // Filter only known roles
    .sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)); // Sort roles based on the preferred order

    return <>{sortedRoles.join(' / ')}</>;
  }
  