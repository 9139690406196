import './App.css';
import {BrowserRouter,Route, Routes} from 'react-router-dom'
import React from 'react';

import Home from './pages/Home';
import NotFound404 from './pages/404';
import Student404 from './pages/students/Student404';
import Parent404 from './pages/studentsParent/pages/Parent404';
import SchoolGeneral404 from './pages/schoolGeneral/SchoolGeneral404';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import UserPendingActivationSetup from './pages/UserPendingActivation';
import Welcome from './pages/Welcome';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import UnAuthenticated from './pages/users/UnAuthenticated';

// import VisitorPage from './pages/VisitorPage';
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './pages/ResetPassword';

import About from './pages/About';
import CoppaTerms from './pages/CoppaTerms';

// access control services
import StudentAccess from './services/StudentAccess';
import CounselorAccess from './services/CounselorAccess';
import SuperAdminAccess from './services/SuperAdminAccess';
import SchoolAdminAccess from './services/SchoolAdminAccess';
import SchoolStaffAccess from './services/SchoolStaffAccess';
import ParentAccess from './services/StudentParentsAccess';
import RequireAuthRoutes from "./services/RequireAuthRoutes";
import PersistentLogin from './services/PersistentLogin';
// check services
import ArchiveCheck from './services/ArchiveCheck';
import SubscriptionCheck from './services/SubscriptionCheck';
import ActivationCheck from './services/ActivationCheck';

// providers
import EventFormProvider from './pages/schoolGeneral/counselor/pages/CreateEvent/providers/EventForm_Context';
import EventFormValidationProvider from './pages/schoolGeneral/counselor/pages/CreateEvent/providers/EventFormValidation_Context';
import SchoolNewEventFormProvider from './pages/schoolGeneral/School_All_CreateEvent/providers/SchoolEventFormContext';
import SchoolNewEventFormValidationProvider from './pages/schoolGeneral/School_All_CreateEvent/providers/SchoolEventFormValidationContext';
import EditEventFormProvider from './pages/schoolGeneral/counselor/pages/EditEvent/providers/EditEvent_FormContext';
import EditEventFormValidationProvider from './pages/schoolGeneral/counselor/pages/EditEvent/providers/EditEvent_FormValidationContext';
// import { NewUniFormProvider } from './pages/admin/adminComponents/createUniversityForm/providers/CreateUniversityContext';
// import RequiredFieldsProvider from './pages/admin/adminComponents/createUniversityForm/providers/RequiredFieldsContext';

import ForgotPassword from './pages/ForgotPassword';
import StaffActivationBuffer from './pages/StaffActivationBuffer';
import StudentActivationBuffer from './pages/StudentActivationBuffer';
import ParentActivationBuffer from './pages/ParentActivationBuffer';
// import StudentHomeProfile from './pages/students/pages/Student_Home_Profile';

// Super Admin Pages
import SuperAdminSettings from './pages/admin/pages/mainPages/SuperAdmin_Settings';
import SuperAdminHome from './pages/admin/pages/mainPages/SuperAdmin_Home';
import SuperAdminAllSchools from './pages/admin/pages/mainPages/SuperAdmin_AllSchools';
import SuperAdminAllUniversities from './pages/admin/pages/mainPages/SuperAdmin_AllUniversities'
import SuperAdminAllEvents from './pages/admin/pages/mainPages/SuperAdmin_AllEvents';
import SuperAdminSchoolProfile from './pages/admin/pages/subPages/SuperAdmin_SchoolProfile';
import SuperAdminManageSubscription from './pages/admin/pages/mainPages/SuperAdmin_ManageSubscription';
import SuperAdminCreateSchool from './pages/admin/pages/mainPages/SuperAdmin_CreateSchool';
import SuperAdminManageSchoolAdmin from './pages/admin/pages/subPages/SuperAdmin_ManageSchoolAdmin';
import SuperAdminManageSchoolStudents from './pages/admin/pages/subPages/SuperAdmin_ManageSchoolStudents';
import SuperAdminPendingTeachers from './pages/admin/pages/mainPages/SuperAdmin_PendingTeachers';
import SuperAdminUniversityDetails from './pages/admin/pages/subPages/SuperAdmin_UniversityDetails';
import SuperAdminTeacherProfile from './pages/admin/pages/subPages/SuperAdmin_TeacherProfile';
import SuperAdminUserDetailPage from './pages/admin/adminComponents/UserDetailPage';
import SuperAdminEventDetails from './pages/admin/pages/mainPages/SuperAdmin_EventDetails';
import SuperAdminBulkUniversities from './pages/admin/pages/mainPages/SuperAdmin_BulkUniversities';
import SuperAdminSchoolEvents from './pages/admin/pages/subPages/SuperAdmin_SchoolEvents';
import SuperAdminManageSchoolStudentsByGrades from './pages/admin/pages/subPages/SuperAdmin_ManageSchoolStudentsByGrades';
import SuperAdminContact from './pages/admin/pages/mainPages/SuperAdmin_Contact';
// import SuperAdminAllUsers from './pages/admin/pages/mainPages/SuperAdmin_AllUsers';
// import SuperAdminStudentProfile from './pages/admin/pages/subPages/SuperAdmin_StudentProfile';
// import SuperAdminAllSchoolAdmin from './pages/admin/pages/mainPages/SuperAdmin_AllSchoolAdmin';
// import SuperAdminAllStudents from './pages/admin/pages/mainPages/SuperAdmin_AllStudents';


// Administrator Pages
import AdministratorOnboardStudents from './pages/schoolGeneral/administrator/pages/Administrator_OnboardStudents';
import AdministratorStudentProfileArchived from './pages/schoolGeneral/administrator/pages/Administrator_StudentProfile_Archived';
import AdministratorArchivedStudents from './pages/schoolGeneral/administrator/pages/Administrator_ArchivedStudents';
import AdministratorArchive from './pages/schoolGeneral/administrator/pages/Administrator_Archive';
import AdministratorStaffProfile from './pages/schoolGeneral/administrator/pages/Administrator_StaffProfile';
import AdministratorStaff from './pages/schoolGeneral/administrator/pages/Administrator_Staff';
import AdministratorOnboardSuccess from './pages/schoolGeneral/administrator/pages/Administrator_OnboardSuccess';
import AdministratorSettings from './pages/schoolGeneral/administrator/pages/Administrator_Settings';
import AdministratorStudentProfileEdit from './pages/schoolGeneral/administrator/pages/Administrator_StudentProfile_Edit';
import AdministratorStaffProfileArchived from './pages/schoolGeneral/administrator/pages/Administrator_StaffProfile_Archived';

// counselor pages
import CounselorEventDetails from './pages/schoolGeneral/counselor/pages/Event/CounselorEvent_EventDetails';
import CounselorUniversityDetails from './pages/schoolGeneral/counselor/pages/mainPages/subPages/Counselor_UniversityDetails';
import CounselorCreateEvent from './pages/schoolGeneral/counselor/pages/CreateEvent/Counselor_CreateEvent';
import CounselorCalendar from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Calendar';
import CounselorProfile from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Profile';
import CounselorEditEvent from './pages/schoolGeneral/counselor/pages/EditEvent/Counselor_EditEvent';
import CounselorEvents from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Events';
import CounselorUniversities from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_AllUniversities';
// import CounselorMySchool from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_MySchool';

// school all pages
import SchoolAllCalendar from './pages/schoolGeneral/schoolMainPages/School_All_Calendar';
import SchoolAllMySchool from './pages/schoolGeneral/schoolMainPages/School_All_MySchool';
import SchoolAllEvents from './pages/schoolGeneral/schoolMainPages/School_All_Events';
import SchoolAllEventDetails from './pages/schoolGeneral/schoolMainPages/School_All_EventDetails';
import SchoolAllContactUs from './pages/schoolGeneral/schoolMainPages/School_All_ContactUs';
import SchoolAllCreateEvent from './pages/schoolGeneral/School_All_CreateEvent/School_All_CreateEvent';
import SchoolAllEditEvent from './pages/schoolGeneral/School_All_EditEvent/School_All_EditEvent';
import SchoolAllStaffProfile from './pages/schoolGeneral/schoolMainPages/School_All_StaffProfile';
import SchoolEditEventFormProvider from './pages/schoolGeneral/School_All_EditEvent/providers/SchoolEditEventFormContext';
import Subscription from './pages/schoolGeneral/administrator/pages/subpages/Subscription';
import SchoolAllStudentsAll from './pages/schoolGeneral/schoolMainPages/School_All_StudentsAll'
import SchoolAllStudentsByGrade from './pages/schoolGeneral/schoolMainPages/School_All_StudentsByGrade';
import SchoolAllStudentProfile from './pages/schoolGeneral/schoolMainPages/School_All_StudentProfile';

// Student Pages
import StudentSchoolEvents from './pages/students/pages/Student_SchoolEvents';
import StudentSchoolEventDetails from './pages/students/pages/subpages/Student_SchoolEventDetails';
import StudentProfile from './pages/students/pages/subpages/Student_Profile';
import StudentFavorites from './pages/students/pages/Student_Favorite';
import StudentUCCEventDetails from './pages/students/pages/subpages/Student_UccEventDetails';
import StudentUniversityDetails from './pages/students/pages/subpages/Student_UniversityDetails';
import StudentCalendar from './pages/students/pages/Student_Calendar';
import StudentUccEvents from './pages/students/pages/Student_UCCEvents';
import StudentInvitations from './pages/students/pages/Student_Invitations';
import StudentUniversities from './pages/students/pages/Student_Universities';

// parent pages
import ParentHome from './pages/studentsParent/pages/Parent_Home';
import ParentSchoolEvents from './pages/studentsParent/pages/Parent_SchoolEvents';
import ParentStudentProfile from './pages/studentsParent/pages/Parent_Student_Profile';
import ParentInvitations from './pages/studentsParent/pages/Parent_Invitations';
import ParentUniversities from './pages/studentsParent/pages/Parent_Universities';
import ParentChildren from './pages/studentsParent/pages/Parent_Children';
import ParentEnableCheck from './services/ParentEnableCheck';
import ParentProfile from './pages/studentsParent/pages/Parent_Profile';
import ParentUniversityDetails from './pages/studentsParent/pages/Parent_UniversityDetails';
import AdministratorStaffProfileEdit from './pages/schoolGeneral/administrator/pages/Administrator_StaffProfile_Edit';
import AdministratorStaffProfileArchivedEdit from './pages/schoolGeneral/administrator/pages/Administrator_StaffProfile_Archived_Edit';
import AdministratorArchivedStudentProfileEdit from './pages/schoolGeneral/administrator/pages/Administrator_StudentProfile_Archived_Edit';

// import UserArchiveWarning from './pages/UserArchivedWarning';

function App() {

  return (
    <BrowserRouter>
      <Routes>
      {/* public routes */}
        <Route path='login' element={<Login/>}/>
        <Route path='/privacy-policies' element={<PrivacyPolicy/>} />
        <Route path='/terms-of-service' element={<TermsOfService/>} />
        <Route path='coppa-disclosure' element={<CoppaTerms/>} />

        <Route path='about' element={<About/>} />
        <Route path='signup' element={<SignUp/>}/>
        <Route path="unauthenticated" element={<UnAuthenticated />} />
        <Route path='forgotPassword' element={<ForgotPassword/>} />
        <Route path='resetpassword/:resetToken' element={<ResetPassword/>} />
        <Route path='activate/setup' element={<UserPendingActivationSetup />} />
    {/* public routes */}
        <Route path='activate/staff/:activationToken' element={<StaffActivationBuffer />} />
        <Route path='activate/student/:activationToken' element={<StudentActivationBuffer />} />
        <Route path='activate/parent/:activationToken' element={<ParentActivationBuffer />} />
    {/* Protected Routes */}
        <Route element={<PersistentLogin/>} >
          <Route element={<RequireAuthRoutes />}>
    {/* main home regardless role */}
            <Route path='/' element={<Home/>}>
              <Route path='welcome' element={<Welcome/>} />
            </Route>
    {/* User Routes */}
            <Route path='/' >
    {/* / counselor routes */}
            <Route path='user/change-password' element={<ChangePassword />} />
            <Route element={<SchoolStaffAccess/>}>
              <Route element={<ActivationCheck />} >
                  <Route element={<ArchiveCheck />} >
                    <Route path='school' >
    {/*----------------general access to all staff members  ---------------*/}
                      <Route path='staff/profile' element={<SchoolAllStaffProfile />} />
                      <Route element={<SubscriptionCheck />} >
                        <Route path='students' element ={<SchoolAllStudentsAll/>} />
                        <Route path='students/grade/:gradelevel' element={<SchoolAllStudentsByGrade />} />
                        <Route path='students/:studentId' element={<SchoolAllStudentProfile />} />
                        <Route path='calendar' element={<SchoolAllCalendar />} />
                        
                        <Route path='events' element={<SchoolAllEvents/>} />
                        <Route path='events/:eventId' element={<SchoolAllEventDetails/>} />
                        <Route path='events/new' element={
                            <SchoolNewEventFormProvider>
                                <SchoolNewEventFormValidationProvider>
                                      <SchoolAllCreateEvent/>
                                </SchoolNewEventFormValidationProvider>
                            </SchoolNewEventFormProvider>
                          } />
                        <Route path= 'events/:eventId/edit' element={
                          <SchoolEditEventFormProvider>
                            <SchoolAllEditEvent/>
                          </SchoolEditEventFormProvider>
                        } />
                      </Route>
                      <Route path='contact' element={<SchoolAllContactUs />} />

       {/* ------------------ School admin access --------------------------- */}
                      <Route element={<SchoolAdminAccess />} >
                        <Route path='administrator' >
                          <Route element={<SubscriptionCheck />} >
                            <Route path='students/onboard' element={<AdministratorOnboardStudents/>} />
                            <Route path='students/onboard/success' element={<AdministratorOnboardSuccess />} />
                            <Route path='students/:studentId/edit' element={<AdministratorStudentProfileEdit/>} />
                            <Route path='staff' element={ <AdministratorStaff /> } />
                            <Route path='staff/:staffId' element={<AdministratorStaffProfile />}/>
                            <Route path='staff/:staffId/edit' element={<AdministratorStaffProfileEdit />} />
                            <Route path='archive' element={<AdministratorArchive />} />
                            <Route path='archive/staff/:staffId' element={<AdministratorStaffProfileArchived />} />
                            <Route path="archive/staff/:staffId/edit" element={<AdministratorStaffProfileArchivedEdit />} />
                            <Route path='archive/student/:studentId' element={<AdministratorStudentProfileArchived />} />
                            <Route path='archive/student/:studentId/edit' element={<AdministratorArchivedStudentProfileEdit />} />
                            <Route path='settings' element={<AdministratorSettings/>} />
                          </Route>
                          <Route path='myschool' element={<SchoolAllMySchool />} />
                          <Route path='home' element={<SchoolAllMySchool />} />
                          <Route path='subscription' element={<Subscription />} />
                        </Route>
                      </Route>

     {/* ------------------ counselor role access -------------------  */}
                      <Route element={<CounselorAccess />} >
                      <Route element={<SubscriptionCheck />} >
                        <Route path='counselor' >  
                        {/* counselor route start /counselor/ */}
                          <Route path='profile' element={<CounselorProfile />} />
                          <Route path='universities' element ={<CounselorUniversities/>}/>
                          <Route path='universities/:universityId' element={<CounselorUniversityDetails/>} />
                          <Route path='events' element={<CounselorEvents/>}/>
                          <Route path='events/:eventId' element={<CounselorEventDetails/>} />
                          <Route path='events/:eventId/edit' element={
                            <EditEventFormProvider>
                              <EditEventFormValidationProvider>
                                <CounselorEditEvent/>
                              </EditEventFormValidationProvider>
                            </EditEventFormProvider>
                          } />
                          <Route path='new-event' element ={
                            <EventFormProvider>
                              <EventFormValidationProvider>
                                <CounselorCreateEvent/> 
                              </EventFormValidationProvider>
                            </EventFormProvider>
                          }/>
                          <Route path='calendar' element={<CounselorCalendar />} />

                        </Route>      {/* counselor access ends */}
                      </Route>
                      </Route>        {/* counselor access check ends */}
                      <Route path='*' element={<SchoolGeneral404/>} />
                    </Route>      {/* school access checkends */}
                  </Route>       {/* Archive check ends */}
                     {/* subscription check ends */}
              </Route>      {/* Activation check ends */}
            </Route>      {/* School staff access ends */}

 {/*---------------- / Student routes  ------------------*/}
            <Route element={<StudentAccess />}>
              <Route element={<ActivationCheck />} >
                <Route element={<ArchiveCheck />} >
                  <Route element={<SubscriptionCheck />} >
                    <Route path='student' >
                    {/* Student Routes */}
                      <Route path='contact' element={<SchoolAllContactUs />} />
                    {/* need to add a role based access */}
                      <Route path='profile' element={<StudentProfile />} />
                      <Route path='profile' element={<StudentProfile/>}/>
                      <Route path='universities' element={<StudentUniversities/>}/>
                      <Route path='universities/:universityId' element={<StudentUniversityDetails />} />
                      <Route path='school-events' element={<StudentSchoolEvents />} />
                      <Route path='school-events/:eventId' element={<StudentSchoolEventDetails />} />
                      <Route path='ucc-events' element={<StudentUccEvents />} />
                      <Route path='ucc-events/:eventId' element={<StudentUCCEventDetails />}/>
                      <Route path='calendar' element={<StudentCalendar />} />
                      <Route path='invitations' element={<StudentInvitations />} />
                      <Route path='favorites' element ={<StudentFavorites/>} />
                      {/* student specific 404 route */}
                      <Route path='*' element={<Student404/>} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
                  {/* / Parents routes */}
            <Route element={<ParentAccess/>}/>
              <Route element={<ParentEnableCheck/>} >
                <Route element={<ActivationCheck />} >
                  <Route element={<SubscriptionCheck />} >
                  <Route path='parent' >
                      {/* Parent routes */}
                      <Route index element={<ParentHome />} />
                      <Route path='school-events' element={<ParentSchoolEvents />} />
                      <Route path='invitations' element={<ParentInvitations/>} />
                      <Route path="universities" element={<ParentUniversities />} />
                      <Route path='universities/:universityId' element={<ParentUniversityDetails />} />
                      <Route path="Students" element={
                        <ParentChildren />} />
                      <Route path='student-profile/:studentId' element={<ParentStudentProfile />} />
                      <Route path='profile' element={<ParentProfile />} />
                      {/* Student specific 404 route */}
                      <Route path='*' element={<Parent404/>} />
                  </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
  {/* ------------------  superAdmin Routes ------------------------ */}
            <Route element={<SuperAdminAccess />} >
              <Route path='admin' > 
                  {/* need to add a role based access */}
                <Route index element={<SuperAdminHome/>} />
                <Route path='schools' element ={<SuperAdminAllSchools />} />
                <Route path='schools/new' element={<SuperAdminCreateSchool/>} />
                <Route path='schools/:schoolId/details' element = {<SuperAdminSchoolProfile />} />
                <Route path='schools/:schoolId/events' element=
                {<SuperAdminSchoolEvents />} />
                <Route path='schools/:schoolId/events/:eventId' element={<SuperAdminEventDetails />} />
                <Route path='schools/:schoolId/manage-admin' element={<SuperAdminManageSchoolAdmin/>}/> 
                <Route path='schools/:schoolId/manage-subscription' element={<SuperAdminManageSubscription />} />
                <Route path="schools/:schoolId/manage-students" element={<SuperAdminManageSchoolStudents />} />
                <Route path='schools/:schoolId/manage-students/grade/:gradeLevel' element={<SuperAdminManageSchoolStudentsByGrades />}/>
                {/* <Route path='students' element ={<SuperAdminAllStudents />} /> */}
                {/* <Route path='students/:studentId' element={<SuperAdminStudentProfile />} /> */}
                <Route path='universities' element = {<SuperAdminAllUniversities />} />
                <Route path='universities/bulk-add' element={<SuperAdminBulkUniversities />} />

                <Route path='universities/:universityId' element={<SuperAdminUniversityDetails />} />
                {/* <Route path='administrators' element ={<SuperAdminAllSchoolAdmin />} /> */}
                <Route path='teachers/:userId' element={<SuperAdminTeacherProfile />} />
                <Route path='events' element = {<SuperAdminAllEvents />} />
                <Route path='events/:eventId' element={<SuperAdminEventDetails />} />
                {/* <Route path='users' element = {<SuperAdminAllUsers/>} /> */}
                <Route path='users/pending' element={<SuperAdminPendingTeachers/>} />
                <Route path='users/:userId' element={<SuperAdminUserDetailPage/>} />
                <Route path='settings' element={<SuperAdminSettings/>} />
                <Route path='contact' element={<SuperAdminContact />} />
              </Route>
            </Route>
          </Route> 
          <Route path='/*' element={<NotFound404/>}/>
        </Route>
      </Routes>
      {/* Protected Routes end */}
    </BrowserRouter>
  );
}

export default App;
