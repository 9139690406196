import React,{useState,useEffect,useContext,useCallback} from 'react'
import UnAuthLayout from './components/UnAuthLayout'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from '../api/axios';
import { AuthenticationContext } from '../services/AuthContextModule';


export default function ResetPassword() {

    const navigateTo = useNavigate();
    const {resetToken} = useParams();
    const {setAuthState} = useContext(AuthenticationContext);
    
    const [resetpassword, setResetPassword] = useState({
        password:"",
        confirmPassword:""
    });
    const [oldPasswordWarning, setOldPasswordWarning] = useState(true);
    const [newPasswordWarning, setNewPasswordWarning] = useState(true);
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(true);

    const [resetTokenExpired, setResetTokenExpired] = useState(false);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function(...args) {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
      };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setResetPassword((prevState) => {
        const newState = { ...prevState, [name]: value };
        return newState;
      });
    };
    const checkPasswordFormat = ()=>{
      if(resetpassword.password.length > 7){
        setNewPasswordWarning(true)
        return true
      }else{
        setNewPasswordWarning(false)
        return false
      }
    }

    const handleBlur = (e) => {
      const { name } = e.target;
    
      if (name === "password") {
        checkPasswordFormat(resetpassword.password);
        // handleConfirmPassword(resetPassword.newPassword, resetPassword.confirmPassword);
      }
      if(name === "confirmPassword"){
        handleConfirmPassword(resetpassword.password, resetpassword.confirmPassword);
      }
    };

    const debouncedBlurHandler = useCallback(debounce(handleBlur, 300), [resetpassword]);

    const handleSubmit = async (e)=>{
        e.preventDefault();

        if(handleConfirmPassword(resetpassword.password,resetpassword.confirmPassword) && checkPasswordFormat()){
            // send request to reset password
            await axios.put(`/api/auth/resetpassword/${resetToken}`,{password:resetpassword.password},{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setAuthState({
                        accessToken: response.data.accessToken,
                        user:response.data.userId,
                        role:response.data.role,
                        isAuthenticated:true,
                        firstName:response.data.firstName,
                        profilePicture:response.data.profilePicture,
                        isActivated:response.data.activated,
                        isArchived:response.data.archived,
                        schoolId:response.data.schoolId,
                        subscriptionStatus:response.data.subscriptionStatus
                    });
                    navigateTo('/')
                }
            }).catch((err)=>{
                console.log("error here")
                console.log(err)
            })
        }
    }
    const handleConfirmPassword = (newPassword,confirmPassword)=>{
      if(newPassword === confirmPassword){
        setConfirmPasswordWarning(true)
        return true
      }else{
        setConfirmPasswordWarning(false)
        return false
      }
    }

    const checkToken = async()=>{
      await axios.get(`/api/auth/resetpassword/verify/${resetToken}`,{withCredentials:true})
      .then((response)=>{
          if(response.status === 200){
            if(response.data.success === false){
                setResetTokenExpired(true)
            }
          }
      }).catch((err)=>{
          console.log(err)
          setResetTokenExpired(true)
      })
    }
    
    useEffect(()=>{
      checkToken();
    } ,[])

  return (
    <UnAuthLayout>
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {
            resetTokenExpired === false?
            <form>
              <div className='my-2'>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      <p>New Password</p>
                      <p className='text-red-500' hidden={newPasswordWarning}>Password must be at least 8 characters long</p>
                </label>
                <div className="mt-2">
                  <input
                      id="password"
                      onChange={handleChange}
                      value={resetpassword.password}
                      name="password"
                      type="password"
                      autoComplete="password"
                      required
                      className="bg-slate-100 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className='my-2'>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Confirm New Password
                </label>
                <div className="mt-2">
                  <input
                      id="confirmPassword"
                      name="confirmPassword"
                      value={resetpassword.confirmPassword}
                      onChange={handleChange}
                      onBlur={debouncedBlurHandler}
                      type="password"
                      autoComplete="password"
                      required
                      className="bg-slate-100 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div> 
              </div>
              <div className='mt-4'>
                  <button
                      onClick={handleSubmit}
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                      Reset password
                  </button>
              </div>
            </form>
            :   // if reset token has expired
            <div>
              <p className='font-semibold'>Reset Link has expired</p>
            </div>
          }


          </div>
        </div>

      </div>
   
    </div>

    </UnAuthLayout>
  )
}
