import React,{useContext,useEffect,useState,useCallback} from 'react';
import Layout from './components/Layout'
import axiosWithInterceptor from '../hooks/axiosInterceptor'
import { AuthenticationContext } from '../services/AuthContextModule';
import {Link, useNavigate } from 'react-router-dom';

import { useBanner } from '../services/SystemBannerMessage';

export default function ChangePassword() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const {showBanner} = useBanner();
  const navigateTo = useNavigate();

  const [changePassword, setChangePassword] = useState({
    oldPassword:"",
    newPassword:"",
    confirmPassword:""
  });

  const [oldPasswordWarning, setOldPasswordWarning] = useState(true)
  const [newPasswordWarning, setNewPasswordWarning] = useState(true)
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(true)

  // const handleChange = (e)=>{
  //   const {name,value} = e.target;

  //   setResetPassword((prevState)=>{
  //     const newState = {...prevState,[name]:value};
  //     if(name === "newPassword"){
  //       checkPasswordFormat(newState.newPassword);
  //     }
  //     if(name === "confirmPassword"){
  //       handleConfirmPassword(newState.newPassword,newState.confirmPassword);
  //     }
  //     return newState;
  //   })
  // }

  const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setChangePassword((prevState) => {
      const newState = { ...prevState, [name]: value };
      return newState;
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === "newPassword") {
      checkPasswordFormat(changePassword.newPassword);
      // handleConfirmPassword(resetPassword.newPassword, resetPassword.confirmPassword);
    }
    if(name === "confirmPassword"){
      handleConfirmPassword(changePassword.newPassword, changePassword.confirmPassword);
    }
  };

  const debouncedBlurHandler = useCallback(debounce(handleBlur, 300), [changePassword]);

  const handleConfirmPassword = (newPassword,confirmPassword)=>{
    if(newPassword === confirmPassword){
      setConfirmPasswordWarning(true)
      return true
    }else{
      setConfirmPasswordWarning(false)
      return false
    }
  }
  
  const checkPasswordFormat = ()=>{
    if(changePassword.newPassword.length > 7){
      setNewPasswordWarning(true)
      return true
    }else{
      setNewPasswordWarning(false)
      return false
    }
  }

  const handleSubmit = async (e)=>{
    e.preventDefault();
    if(handleConfirmPassword(changePassword.newPassword,changePassword.confirmPassword) && checkPasswordFormat()){
      // send request to change password
      await useAxiosWithInterceptor.post(`/api/user/${userId}/profile/resetpassword`,changePassword,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            showBanner("Password changed successfully","bg-green-500");
            navigateTo('/')
          }
        })
        .catch((error)=>{
          showBanner("An error occurred","bg-yellow-500")
          console.log(error)
        })

    } else {
      console.log("passwords do not match")
    }
  }

  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 px-1 pb-2 lg:mx-2' >
        <p className='text-3xl font-bold py-1 px-2 my-3'>Reset Password</p>
        <div className="px-2 lg:w-1/3 ">
          <form>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">
              <p className='font-semibold'>old password </p>
            </label>
            <input 
              type="password" name="oldPassword" id="oldPassword" autoComplete="old-password" 
              value={changePassword.oldPassword}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-3">
              <p className='font-semibold'>New password</p>
              <p className='text-red-500' hidden={newPasswordWarning}>Password must be at least 8 characters long</p>
            </label>
            <input 
              type="password" name="newPassword" id="newPassword" autoComplete="old-password" 
              value={changePassword.newPassword}
              onChange={handleChange}
              onBlur={debouncedBlurHandler}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-3">
              <p className='font-semibold'>Confirm password</p>
              <p className='text-red-500' hidden={confirmPasswordWarning}>Passwords do not match</p>
            </label>
            <input 
              type="password" name="confirmPassword" id="confirmPassword" autoComplete="old-password" 
              value={changePassword.confirmPassword}
              onChange={handleChange}
              onBlur={debouncedBlurHandler}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
            <div className=" my-3">
              <button 
                type="submit" 
                onClick={handleSubmit}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Change Password
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
    </Layout>
  )
}
