import React, { useEffect,useContext, useState, useRef} from 'react';
import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from '../providers/SchoolEventFormContext';
import { SchoolNewEventFormRequiredContext, SchoolNewEventFormRequiredDispatchContext } from '../providers/SchoolEventFormValidationContext';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';

import { XCircleIcon ,CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function SchoolEventParentSelector({attributeName}) {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
      // contexts
    const eventFormState = useContext(SchoolNewEventFormContext);
    const dispatch = useContext(SchoolNewEventFormDispatchContext);

    const requiredFields = useContext(SchoolNewEventFormRequiredContext);
    const formCheckDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

    const [gradeLevels, setGradeLevels] = useState([]);
    const [allParents, setAllParents] = useState([]);

    const getAllParents = async ()=>{
        await useAxiosWithInterceptor.get(`api/user/${userId}/school/${schoolId}/students/all-parents`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setAllParents(response.data.payload);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    
    useEffect(()=>{
        getAllParents();
    },[])

  return (
    <>
    <div className="mx-2">
      <form className='my-1 col-span-2'>
        <div className='flex flex-wrap text-xs font-semibold'>
        {
            gradeLevels.map((grade,index)=>{
                return (
                  grade && 
                    <div key={index} className='flex space-x-1 py-2 mx-2'>
                      <input 
                        type='checkbox' 
                        // onChange={handleChecked} 
                        
                        value={grade} 
                        // checked={checkedOrNot(grade)}
    // if Context contains this grade value? checked
                        label={`Grade ${grade}`}
                        className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      <label className='text-sm'> Grade {grade} 
                        <span className='text-blue-500'>
                        {/* ({gradeCount(grade)}) */}
                        </span>
                      </label>
                    </div>
                  )
            })
        }
        </div>
      </form>
    </div>
     {/* only shows total count of selected parents by gradeLevel */}
    <div className='pb-3 pt-1'>

    </div>
    </>
  )
}
