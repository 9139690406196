import React,{useState,useEffect,} from 'react';
import Layout from '../../../components/Layout';
import StudentLayout from '../../../components/Layouts/StudentLayout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { useNavigate,useLocation,Link } from 'react-router-dom';

import useAuthenticationContext from '../../../../hooks/authStateHook';
import ProfileAvatar from '../../../components/Avatar';
import moment from 'moment';
import SkeletonLoader from '../../../pageComponents/SkeletonLoader';
import SkeletonTableRow from '../../../pageComponents/SkeletonTableRow';
import { delayLoading } from '../../../../utils/loadingDelay';

export default function StudentProfile() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useAuthenticationContext();  
    const schoolId = authState.schoolId;
    const userId = authState.user;
    
    const navigateTo = useNavigate();
    const location = useLocation();

    const [studentInfo, setStudentInfo] = useState();
    const [studentDetailedInfo, setStudentDetailedInfo] = useState({});

    const [invitations, setInvitations] = useState([]);
    const [parentInfoObj,setParentInfoObj] = useState({
      fatherFirstName:"",
      fatherLastName:"",
      fatherEmail:"",
      motherFirstName:"",
      motherLastName:"",
      motherEmail:"",
  });

    const [isPageLoading, setIsPageLoading] = useState(true);
    
    const getStudentInfo = async ()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/profile`,{withCredentials:true})
          .then((response)=>{
              if(response.status === 200){
                setStudentInfo(response.data.payload);
                setStudentDetailedInfo(response.data.payload.studentInformation);
                let parentInfo = response.data.payload.parentsInformation;
                let motherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "mother");
                let fatherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "father");
                let guardianInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "guardian");

                setParentInfoObj({
                  fatherFirstName:fatherInfo?.parent?.firstName??"",
                  fatherLastName:fatherInfo?.parent?.lastName??"",
                  fatherEmail:fatherInfo?.parent?.email??"",
                  motherFirstName:motherInfo?.parent?.firstName??"",
                  motherLastName:motherInfo?.parent?.lastName??"",
                  motherEmail:motherInfo?.parent?.email??"",
                });
                setInvitations(response.data.payload.invitations);
              }
            }
          )
          .catch((err)=>{
            // setAuthState({})
            console.log(err)
            // navigateTo('/login',{state:{from: location},replace:true});
          })
          .finally(()=>{
            setIsPageLoading(false);
          });
      }
    
      useEffect(()=>{
        // delayLoading(getStudentInfo,3000)
        getStudentInfo(); 
        // console.log(studentInfo)
      },[]);
  return (
    <StudentLayout>
    <div className='md:w-full w-full '>
      <div className='bg-opacity-75 px-1 py-2 lg:mx-2'>
        <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl shadow-md bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={studentInfo} size={20}/>
              </div>
              {/* <div className='text-center'>
                <UserEditPhoto refreshUser={getUser} />
              </div> */}
            </div>
            <div className=''>
              <div className='my-2'>
                {
                  isPageLoading? 
                  <SkeletonLoader width={56} height={10} style={'bg-indigo-400 rounded'} />
                  :  //if page loading is false
                  (<p className='font-bold text-3xl text-white'>{studentInfo?.firstName } {studentInfo?.lastName}</p>)
                }
              </div>
              <div className='flex space-x-5 items-center text-white'>
                {
                  isPageLoading?
                  <SkeletonLoader width={56} height={6} style={'bg-indigo-400 rounded'} />
                  : //if page loading is false
                  <p className='font-semibold text-lg'>
                    {studentInfo?.gradeLevel && "Class of "+ calculateClassYear(studentInfo?.gradeLevel)} {studentInfo?.gradeLevel && "(Grade "+studentInfo?.gradeLevel+")"
                    }
                  </p>
                }
              </div>
            </div>
          </div>
                    {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
            </div>

            <div className='lg:grid grid-cols-3 px-4'>
              <div id='column-one' className=''>
                <div className='flex space-x-5 items-center px-3 py-2'>
                  <p className='text-sm'>Email:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={'bg-slate-200 rounded'} />
                    : //if page loading is false
                    <p className='font-semibold text-sm'>{studentInfo?.email}</p>
                  } 
                </div>
                {/* <div className='flex space-x-5 items-center px-3 py-2'>
                  <p className='text-sm'>Member Since:</p>
                  <p className='font-semibold text-sm'>{studentInfo?.createdAt.split("T")[0]}</p>
                </div> */}
                <div className='flex space-x-5 items-center px-3 py-2'>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={'bg-slate-200 rounded'} />
                    : //if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>{studentInfo?.school?.schoolName}</p>
                  }
                </div>
              </div>

              <div id='column-two'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Date of Birth:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>
                      { studentDetailedInfo?.dateOfBirth && moment(studentDetailedInfo?.dateOfBirth).format("MMM Do, YYYY")}
                    </p>
                  }
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 text-sm'>
                  <p>Nationality:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>{studentDetailedInfo?.nationality}</p>
                  }
                </div>
              </div>
              <div id='column-three'>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                    <p className='text-sm'>Father:</p>
                    <p className='text-blue-500'>
                      {parentInfoObj?.fatherFirstName} {parentInfoObj?.fatherLastName} 
                    </p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                    <p className='text-sm'>Email:</p>
                    <p className='font-semibold text-blue-500'>
                      {parentInfoObj?.fatherEmail}
                    </p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                  <p className='text-sm'>Mother:</p>
                  <p className='text-blue-500'>
                    {parentInfoObj?.motherFirstName} {parentInfoObj?.motherLastName}
                  </p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                  <p className='text-sm'>Email:</p>
                  <p className='font-semibold text-blue-500'>
                    {parentInfoObj?.motherEmail}
                  </p>
                </div>
              </div>
            </div>
            
          </div>
          <div className='flex space-x-5 items-center px-3 py-2 '>
            <Link to={`/user/change-password`} className='px-2 py-1 bg-blue-500 rounded text-white font-semibold text-sm'>Change Password</Link>
          </div>
          <div className='shadow-md rounded'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p>Activities</p>
            </div>
            <div className=''>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-2/6">
                      Event
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/6">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/6">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/6">
                      Event Type
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                  isPageLoading?
                  <SkeletonTableRow colNumber={4} rowNumber={5} />
                  :   // after page loading
                  invitations.map((invitation) => (
                  <tr key={invitation.id}>
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 w-2/6 break-words">
                      <Link to={`/student/school-events/${invitation?.event?.id}`} className='text-blue-500'>
                        {invitation?.event?.eventName}
                      </Link>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-xs font-semibold text-blue-500 w-1/6 break-words">
                      <p>
                      {invitation?.event?.eventDateTime && moment(invitation?.event?.eventDateTime).format("MMM Do, YYYY")}
                      </p>
                    </td>
                    <td className={`whitespace-nowrap px-3 py-2  font-semibold text-xs w-1/6 break-words ${invitation?.status === "accepted"?"text-green-600":invitation?.status ==="declined"?"text-red-500":"text-amber-500"}`}>{invitation?.status?.toUpperCase()}
                    </td>
                    <td className="whitespace-nowrap px-3 text-gray-500 font-semibold w-2/6 break-words">
                      <p className='shadow-sm rounded-2xl bg-blue-500 text-white w-fit px-2 py-1 text-xs'>
                        {invitation?.event?.eventCategory?.categoryName}
                      </p>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    </StudentLayout>
  )
}

const calculateClassYear = (currentGrade)=>{
    let graduationGrade = 12;
    let currentYear = moment().year();
    let classYear = currentYear + (graduationGrade - currentGrade);
    return classYear;
}