import React,{useContext,useEffect,useState} from 'react';
import Layout from '../../../components/Layout';
import StaffLayout from '../../../components/Layouts/StaffLayout';

import { Link, useLocation, useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import CounselorDeleteStudent from '../administratorComponents/DeleteStudentButton';
import ProfileAvatar from '../../../components/Avatar';
import UnArchiveStudentButton from '../administratorComponents/UnArchiveStudentButton';
import { useBanner } from '../../../../services/SystemBannerMessage';
import moment from 'moment';
import SeparatorLine from '../../schoolComponents/SeparatorLine';
import SkeletonLoader from '../../../pageComponents/SkeletonLoader';
import SkeletonTableRow from '../../../pageComponents/SkeletonTableRow';
import { find } from 'linkifyjs';
import UserEditPhoto from '../../../components/UserEditPhoto';
import { PencilIcon } from '@heroicons/react/24/outline';
import StudentsGradeOptions from '../administratorComponents/StudentsGradeOptions';
import SchoolAdminDeleteStudent from '../administratorComponents/DeleteStudentButton';
import SchoolAdminArchiveStudentButton from '../administratorComponents/ArchiveStudentButton';


export default function AdministratorStudentProfileArchived() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {studentId} = useParams();



    // const location = useLocation();
    const navigateTo = useNavigate();
    const {showBanner} = useBanner();
    const location = useLocation();
    const [isPageLoading,setIsPageLoading] = useState(true);

    const [studentInfo,setStudentInfo] = useState({});
    const [studentDetailedInfo, setStudentDetailedInfo] = useState({});
    const [invitations, setInvitations] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [eventCategories, setEventCategories] = useState([]);

    const [parentInfoObj,setParentInfoObj] = useState({
        fatherFirstName:"",
        fatherLastName:"",
        fatherEmail:"",
        motherFirstName:"",
        motherLastName:"",
        motherEmail:"",
    });

    const getStudentInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/students/archived/${studentId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setStudentInfo(response.data.payload);
                    setStudentDetailedInfo(response.data.payload.studentInformation);
                    
                    let parentInfo = response.data.payload.parentsInformation;
                    let motherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "mother");
                    let fatherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "father");
                    let guardianInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "guardian");
                    setParentInfoObj({
                      fatherFirstName:fatherInfo?.parent?.firstName??"",
                      fatherLastName:fatherInfo?.parent?.lastName??"",
                      fatherEmail:fatherInfo?.parent?.email??"",
                      motherFirstName:motherInfo?.parent?.firstName??"",
                      motherLastName:motherInfo?.parent?.lastName??"",
                      motherEmail:motherInfo?.parent?.email??"",
                  });

                    // const universityNames = response.data.payload.findFavorites.map((favorite)=>{
                    //     return favorite.university.schoolName
                    // })
                    // setFavorites(response.data.payload.findFavorites);
                    // setInvitations(response.data.payload.findStudentInvitations);
                }
            }).catch((error)=>{
                console.log(error)
                showBanner("Archive Not found, back to main page","bg-slate-200")
                navigateTo('/school/administrator/archive',{replace:true})
            }).finally(()=>{
                setIsPageLoading(false);
            } )
    }

    // const handleDelete = async()=>{
    //     await useAxiosWithInterceptor.delete(`/api/user/${userId}/counselor/students/${studentId}`,{withCredentials:true})
    //     .then((response)=>{
    //         if(response.status === 200){
    //             navigateTo('/user/counselor/students',{state:{from: location},replace:true})
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    useEffect(()=>{
        getStudentInfo();
    },[]);

    useEffect(()=>{
        document.title = studentInfo?.firstName +" "+ studentInfo?.lastName
    },[studentInfo])
  
    return (
    <>
    <StaffLayout>
      <div className='w-full h-full'>
        <div className="bg-opacity-75 rounded-xl p-2 lg:mx-2">
          <div className='flex space-x-10 w-full px-3 py-2 rounded-lg  bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={studentInfo} size={20} textSize={'text-3xl'}/>
              </div>
              <div className='text-center'>
                <UserEditPhoto refreshUser={getStudentInfo} />
              </div>
            </div>
            <div className=''>
              <div className='my-2'>
                {
                  isPageLoading?
                  <SkeletonLoader width={56} height={10} style={"bg-indigo-400 rounded"} />
                  :   // if page loading is false
                  <p className='font-bold text-3xl text-white'>{studentInfo?.firstName +" "+studentInfo?.lastName}</p>
                }
              </div>
              <div className='flex space-x-5 items-center text-white'>
                {
                  isPageLoading?
                  <SkeletonLoader width={56} height={6} style={"bg-indigo-400 rounded"} />
                  :   // if page loading is false
                  <p className='font-semibold text-lg'>
                    {studentInfo?.gradeLevel && "Class of "+ calculateClassYear(studentInfo?.gradeLevel)} {studentInfo?.gradeLevel && "(Grade "+studentInfo?.gradeLevel+")"
                    }
                  </p>
                }
              </div>
            </div>
          </div>
                        {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
            <div className='text-lg py-1 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
              {
                authState.role.includes("administrator")?
                  <Link to={`/school/administrator/archive/student/${studentInfo?.id}/edit`}>
                    <button>
                      <PencilIcon className='w-6 h-6' />
                    </button>
                  </Link>
                :   // if not administrator
                null
              }
            </div>

            <div className='lg:grid grid-cols-2'>
              <div id='column-one' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Email:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>{studentInfo?.email} </p>
                  }
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>School: </p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>{studentInfo?.school?.schoolName} </p>
                  }
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Grade:</p>
                  {
                    isPageLoading
                    ?
                    <SkeletonLoaders count={9} width={7} height={5} style="bg-slate-200 rounded" />
                    :   // if page loading is false
                    (studentInfo?.gradeLevel && <StudentsGradeOptions studentInformation={studentInfo} refreshStudentInfo={getStudentInfo} />)
                  }
                </div>
              </div>

              <div id='column-two' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Date of Birth:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>
                      { studentDetailedInfo?.dateOfBirth && moment(studentDetailedInfo?.dateOfBirth).format("MMM Do, YYYY")}
                    </p>
                  }
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Nationality:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>{studentDetailedInfo?.nationality}</p>
                  }
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Member Since:</p>
                  {
                    isPageLoading?
                    <SkeletonLoader width={48} height={5} style={"bg-slate-200 rounded"} />
                    :   // if page loading is false
                    <p className='font-semibold text-blue-500 text-sm'>
                      {
                        studentInfo?.createdAt &&
                        moment(studentInfo?.createdAt).format("MMM Do, YYYY")
                      }
                    </p>
                  }
                </div>
              </div>
            </div>
            <SeparatorLine margin={1} />
            <div id='parents-info' className='grid grid-cols-2' >
                <div>
                  <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                      <p className='font-semibold text-sm '>Father:</p>
                      <p className='text-blue-500'>
                          {parentInfoObj?.fatherFirstName} {parentInfoObj?.fatherLastName} 
                      </p>
                  </div>
                  <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                      <p className='text-sm'>Email:</p>
                      <p className='font-semibold text-blue-500'>
                          {parentInfoObj?.fatherEmail}
                      </p>
                  </div>
                </div>

                <div>
                  <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                    <p className='text-sm'>Mother:</p>
                    <p className='text-blue-500'>
                      {parentInfoObj?.motherFirstName} {parentInfoObj?.motherLastName}
                    </p>
                  </div>
                  <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                    <p className='text-sm'>Email:</p>
                    <p className='font-semibold text-blue-500'>
                      {parentInfoObj?.motherEmail}
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div >
            {
                authState.role.includes("administrator")?
                <div className='flex justify-end space-x-5 my-2'>
                    <SchoolAdminDeleteStudent student={studentInfo} />
                    <UnArchiveStudentButton student={studentInfo} />
                </div>
                : null
            }
          </div>
          
          <div className=" flow-root rounded">
            <div className=" overflow-x-auto rounded">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded">
                  <div className='text-lg py-1 px-3 rounded-t font-semibold flex justify-between bg-slate-200'>
                      <p>Activities</p>
                  </div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-2/6">
                          Event
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/6">
                          Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/6">
                          Status
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/6">
                          Event Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {
                        isPageLoading?
                        <SkeletonTableRow colNumber={4} rowNumber={5} />
                        :   // after page loading
                        invitations.map((invitation) => (
                        <tr key={invitation.id}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-sky-600 sm:pl-6 w-2/6 break-words">
                            <Link to={`/school/events/${invitation?.event?.id}`}>
                            {invitation?.event?.eventName}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-xs font-semibold text-slate-500 w-1/6 break-words">
                            <p>
                            {invitation?.event?.eventDateTime && moment(invitation?.event?.eventDateTime).format("MMM Do, YYYY")}
                            </p>
                          </td>
                          <td className={`whitespace-nowrap px-3 py-2  font-semibold text-xs w-1/6 break-words ${invitation?.status === "accepted"?"text-green-600":invitation?.status ==="declined"?"text-red-500":"text-amber-500"}`}>{invitation?.status?.toUpperCase()}
                          </td>
                          <td className="whitespace-nowrap px-3 text-gray-500 font-semibold w-2/6 break-words">
                            <p className='shadow-sm rounded-2xl bg-blue-500 text-white w-fit px-2 py-0.5 text-xs'>
                              {invitation?.event?.eventCategory?.categoryName}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
            {/* user information section */}
        </div>
      </div>
    </StaffLayout>
    </>
  )
}

const calculateClassYear = (currentGrade)=>{
    let graduationGrade = 12;
    let currentYear = moment().year();
    let classYear = currentYear + (graduationGrade - currentGrade);
    return classYear;
}

const SkeletonLoaders = ({ count, width, height, style }) => (
  <div className='flex space-x-1'>
    {Array.from({ length: count }).map((_, index) => (
      <SkeletonLoader key={index} width={width} height={height} style={style} />
    ))}
  </div>
);