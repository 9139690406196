import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getNames } from 'country-list';

export default function UserCountryPicker({ handleSelect, originalCountry }) {
    const formattedOptions = getNames().map((country) => ({
        value: country,
        label: country
    }));

    // Initialize state for selected country
    const [selectedCountry, setSelectedCountry] = useState(null);

    // Update selectedCountry when originalCountry changes
    useEffect(() => {
        const foundCountry = formattedOptions.find(option => option.value === originalCountry) || null;
        setSelectedCountry(foundCountry);
        console.log("Updated country:", foundCountry?.label);
    }, [originalCountry]); // Re-run when originalCountry changes

    return (
      <Select
          closeMenuOnSelect={true}
          options={formattedOptions}
          onChange={(selectedOption) => {
              setSelectedCountry(selectedOption);
              handleSelect(selectedOption);
          }}
          value={selectedCountry} // ✅ Ensures the correct country is displayed
      />
    );
}
