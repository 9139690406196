import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import UnAuthLayout from './components/UnAuthLayout';
import SeparatorLine from './schoolGeneral/schoolComponents/SeparatorLine';
import { useBanner } from '../services/SystemBannerMessage';

export default function ForgotPassword() {

  const [email,setEmail] = useState();
  const [validated,setValidated] = useState(false);
  const [password,setPassword] = useState('');

  const [requestSuccess, setRequestSuccess] = useState(false);

  const navigateTo = useNavigate();
  const { showBanner } = useBanner();

  const handleEmailChange = (e)=>{
    setEmail(e.target.value);
}

  const handleSubmit =(e)=>{
    e.preventDefault();
    const form = new FormData();
    form.append('email', email)

    axios.post("/api/auth/forgotpassword",form)
      .then((response) =>{
        if(response.status === 200){
          // show a message that the password reset link has been sent
          setRequestSuccess(true);
        }
      })
      .catch((error)=>{
        showBanner("error","An error occurred. Please try again later.")
        console.log(error)
    })
  }

  return (
    <>
    <UnAuthLayout>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Recover your password
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {
            requestSuccess === false?
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
                <SeparatorLine margin={3} />
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send password reset link
                  </button>
                </div>
              </form>
              :
              <div className="relative ">
                <p className='font-semibold text-cyan-600'>
                  A password reset link has been sent to your email address. Please check your email to reset your password.
                </p>
              </div>
          }
          </div>
        </div>

      </div>
    </UnAuthLayout>
    </>
  )
}
