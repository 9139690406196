import React, { useContext, useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { Link,useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import Layout from '../../components/Layout';
import ParentLayout from '../../components/Layouts/ParentLayout';


export default function ParentChildren() {
    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const navigate = useNavigate();

    const [children, setChildren] = useState([]);

    const [loading, setLoading] = useState(false);
    
    // get children function

    const getChildren = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/parent/children`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setChildren(response.data.payload);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=>{
        getChildren();
    },[])

  return (
    <>
    <ParentLayout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75  px-1 py-2 lg:mx-2'>
        <p className='font-semibold mb-4'>Students</p>
        <table className="min-w-full divide-y divide-gray-300 bg-gray-200">
        <thead>
            <tr>
            <th scope="col" className="py-3 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Name
            </th>
            <th scope="col" className="px-3 text-left text-sm font-semibold text-gray-900">
                Grade
            </th>
            <th scope="col" className="px-3 text-left text-sm font-semibold text-gray-900">
                Email
            </th>
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {children.map((child) => (
            <tr key={child.id}>
                <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-0 font-semibold">
                <Link to={`/parent/student-profile/${child.student.id}`}>
                {child.student.firstName} {child.student.lastName}
                </Link>
                </td>
                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{child.student.gradeLevel}</td>
                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{child.student.email}</td>
            </tr>
            ))}
        </tbody>
        </table>
      </div>
    </div>
    </ParentLayout>
    </>
  )
}
