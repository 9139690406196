import React,{useEffect} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { getYear, getMonth } from 'date-fns';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';

export default function UserBDayPicker({originalDate,handleSelect}) {
  
  const [startDate, setStartDate] = useState(originalDate? new Date(originalDate) : null);
  const years = range(1970, getYear(new Date()) + 1, 1);
  
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setStartDate(originalDate ? new Date(originalDate) : null);
  }, [originalDate]);

  return (
    <DatePicker className='bg-slate-100 px-2 py-1 rounded-md text-center'
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => ( 
        <div
            className='flex justify-center m-2 space-x-2 font-semibold '
         >
          <button 
            onClick={decreaseMonth} 
            disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button 
            onClick={increaseMonth} 
            disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        handleSelect(date);
      }}
    />
  )
}
