import React,{useContext,useEffect,useState} from 'react';
import StaffLayout from '../../../components/Layouts/StaffLayout';
import {Link, useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import ProfileAvatar from '../../../components/Avatar';
import { useBanner } from '../../../../services/SystemBannerMessage';
import moment from 'moment';
import SeparatorLine from '../../schoolComponents/SeparatorLine';
import 'react-datepicker/dist/react-datepicker.css';
import UserBDayPicker from '../administratorComponents/UserBirthdayPicker';
import UserCountryPicker from '../administratorComponents/UserCountryPicker';

export default function AdministratorStaffProfileEdit() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const {showBanner} = useBanner();
    const navigateTo = useNavigate();

    const userId = authState.user;
    const {staffId} = useParams();
    const schoolId = authState.schoolId;

    const [staffInfo, setStaffInfo] = useState({});

    const [staffInfoObj, setStaffInfoObj] = useState({
        firstName:"",
        lastName:"",
        gender:"",
        email:"",
        dateOfBirth:"",
        nationality:"",
    });
    const [originalStaffInfoObj, setOriginalStaffInfoObj] = useState({
        firstName:"",
        lastName:"",
        gender:"",
        email:"",
        dateOfBirth:"",
        nationality:"",
    });

    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = ()=>{
        setIsEditing(!isEditing);
        setStaffInfoObj({
            firstName:originalStaffInfoObj.firstName,
            lastName:originalStaffInfoObj.lastName,
            gender:originalStaffInfoObj.gender,
            email:originalStaffInfoObj.email,
            dateOfBirth:originalStaffInfoObj.dateOfBirth,
            nationality:originalStaffInfoObj.national
        })
    }

    // 
    const handleStaffInfoChange = (e)=>{
        const {name,value} = e.target;
        setStaffInfoObj((prev) => ({
            ...prev,
            [name]: value === "" && name === "gender" ? null : value, // Set `null` if gender is empty
        }));
    }

    const handleBirthdayChange = (newBirthday)=>{
        setStaffInfoObj((prev) => ({
            ...prev,
            dateOfBirth: newBirthday,
        }));
    }
    
    const handleCountryChange = (selectedOption) => {
        setStaffInfoObj((prev)=>({
            ...prev,
            nationality: selectedOption.value
        }));
    }
    
    // so no empty spaces are saved
    const handleStaffInfoBlur = (e)=>{
        setStaffInfoObj((prev) => ({
            ...prev,
            [e.target.name]: e.target.value.trim(),
        }));
    }

    const getStaffInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/teachers/${staffId}`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                // console.log(response.data.payload);
                setStaffInfo({
                    firstName:response.data.payload.firstName,
                    lastName:response.data.payload.lastName,
                    email:response.data.payload.email,
                    gender:response.data.payload.gender,
                    dateOfBirth:response.data.payload.dateOfBirth,
                    nationality:response.data.payload.nationality,
                    createdAt:response.data.payload.createdAt
                });
                setStaffInfoObj({
                    firstName:response.data.payload.firstName,
                    lastName:response.data.payload.lastName,
                    email:response.data.payload.email,
                    gender:response.data.payload.gender,
                    dateOfBirth:response.data.payload.dateOfBirth,
                    nationality:response.data.payload.nationality,
                    createdAt:response.data.payload.createdAt

                });
            }
        })
        .catch((error)=>{
            console.log(error);
            showBanner("Staff Not found")
        })
    }

    const handleStaffInfoSave = async()=>{
        const updatedStaffInfo = {};
        Object.keys(staffInfoObj).forEach((key)=>{
            if(staffInfoObj[key] !== staffInfo[key]){
                updatedStaffInfo[key] = staffInfoObj[key];
            }
        })

        // check age is at least 18 years old
        let newBirthday = new Date(updatedStaffInfo.dateOfBirth);
        const today = new Date();
        const minAllowedDate = new Date();
        minAllowedDate.setFullYear(today.getFullYear() - 18);
        if (newBirthday > minAllowedDate) {
            showBanner("Staff must be at least 18 years old", "bg-yellow-500");
            return;
        }
    
        if(Object.keys(updatedStaffInfo).length === 0){
            showBanner("No changes made");
            navigateTo(`/school/administrator/staff/${staffId}`,{replace:true});
            return;
        }

        if(updatedStaffInfo.email && !validateEmail(updatedStaffInfo.email)){
            showBanner("Invalid email address","bg-yellow-500");
            return;
        }
        
        if( new Date().getFullYear() - new Date(newBirthday).getFullYear() < 10){
            showBanner("Invalid date of birth","bg-yellow-500");
            return;
        }

        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/teachers/${staffId}/edit`,updatedStaffInfo,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Staff Info Updated", "bg-green-500");
                navigateTo(`/school/administrator/staff/${staffId}`,{replace:true});
            }
        })
        .catch((error)=>{
            console.log(error);
            showBanner("Failed to update staff info","bg-amber-500");
        })
    }

    // ------- email validation
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    useEffect(()=>{
        // delayLoading(getStudentInfo,2000)
        getStaffInfo();
    },[]);

    useEffect(()=>{
        document.title = staffInfo?.firstName && staffInfo?.lastName 
        ? `${staffInfo.firstName} ${staffInfo.lastName}` 
        : "student profile"
    },[staffInfo])

  return (
    <StaffLayout>
    <div className='w-full min-h-screen'>
        <div className="bg-opacity-75 rounded-2xl p-2 lg:mx-2">
        {/*  title */}
            <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl bg-indigo-500'>
                <div id='student-basic' className=''>
                    <div className='flex justify-center'>
                        <ProfileAvatar user={staffInfo} size={20} textSize={'text-3xl'}/>
                    </div>
                </div>
                <div className=''>
                    <div className='my-2'>
                        <p className='font-bold text-3xl text-white'>{staffInfo?.firstName} {staffInfo?.lastName}</p>
                    </div>
                </div>
            </div>
            {/* basic info */}
            <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
                <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
                    <p >Basic Information</p>
                    {/* <PencilSquareIcon className='w-5 h-5 cursor-pointer' onClick={toggleEdit}/> */}
                </div>
            </div>
            {/* edit form */}
            <div className=' px-2 bg-white rounded'>
                <div id='column-one' className='col-span-3'>
                    <div className='flex space-x-4 w-full'>
                        <div className='items-center w-full px-3 space-y-2'>
                            <div>
                                <p className='text-sm'>First Name:</p>
                                <input 
                                    type='text' 
                                    name='firstName'
                                    value={staffInfoObj?.firstName}
                                    onChange={handleStaffInfoChange}
                                    onBlur={handleStaffInfoBlur}
                                    className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                />
                            </div>
                            <div>
                                <p className='text-sm'>Last Name:</p>
                                <input 
                                    type='text' 
                                    name='lastName'
                                    value={staffInfoObj?.lastName}
                                    onChange={handleStaffInfoChange}
                                    onBlur={handleStaffInfoBlur}
                                    className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                />
                            </div>
                            <div>
                                <p className='text-sm'>Gender</p>
                                <select
                                    className="border border-gray-300 rounded px-2 py-1 w-80 text-blue-500"
                                    name="gender"
                                    value={staffInfoObj?.gender ?? ""} // Default to "" if gender is null or undefined
                                    onChange={handleStaffInfoChange}
                                >
                                    <option value="">Select gender</option> 
                                    <option value="male">male</option>
                                    <option value="female">female</option>
                                    <option value="other">other</option>
                                </select>
                            </div>
                            <p className='text-sm'>Email:</p>
                            <div className='space-x-2 flex items-center'>
                                <input 
                                    type='text' 
                                    name='email'
                                    value={staffInfoObj?.email}
                                    onChange={handleStaffInfoChange}
                                    onBlur={handleStaffInfoBlur}
                                    className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                />
                            </div>
                            <div className='items-center py-2'>
                                <p className='text-sm'>Date of Birth:</p>
                                <UserBDayPicker handleSelect={handleBirthdayChange} originalDate={staffInfoObj?.dateOfBirth} />
                            </div> 
                            <div className='items-center py-2 '>
                                <p className='text-sm'>Nationality:</p>
                                <div className='w-80'>
                                    <UserCountryPicker 
                                        handleSelect={handleCountryChange}
                                        originalCountry={staffInfoObj?.nationality} 
                                    />
                                </div>
                            </div>
                            
                            <div className='flex space-x-5 items-center py-2 '>
                                <p className='text-sm'>Member Since:</p>
                                <p className='font-semibold text-blue-500 text-sm'>
                                {staffInfoObj?.createdAt && moment(staffInfoObj?.createdAt).format("MMM Do, YYYY")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex space-x-5 items-center px-3 m7 py-2 justify-end mt-3'>
                        <div className='flex space-x-5 font-semibold text-sm '>
                            <Link to={`/school/administrator/staff/${staffId}`}>
                            <button className='bg-slate-300 rounded lg:w-40 md:w-40 w-full py-1 px-2'>Cancel</button>
                            </Link>
                            
                            <button 
                                className='bg-blue-500 rounded lg:w-40 md:w-40 w-full py-1 text-white px-2'
                                onClick={handleStaffInfoSave}
                                >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </StaffLayout>
  )
}
