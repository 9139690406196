import React, { useContext, useState, Fragment, useRef} from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import {AuthenticationContext } from '../../../../services/AuthContextModule';
import { useLocation,useNavigate} from "react-router-dom";
import { TrashIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


export default function SchoolAdminDeleteStudent({student}) {

    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const navigateTo = useNavigate();
    const location = useLocation();

    const [deleteConfirm, setDeleteConfirm] = useState('');
    const handleDeleteConfirm = (e)=>{
        let value = e.target.value;
        setDeleteConfirm(value);
        if(value === 'DELETE'){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }
    }

    const handleShowModal = ()=>{
        // setShowModal(true)
        setOpen(true)
    }

    const handleCloseModal = ()=>{
        setShowModal(false)
    }

    const handleDelete = async()=>{
        if(deleteConfirm === 'DELETE'){

        await useAxiosWithInterceptor.delete(`/api/user/${userId}/school/${schoolId}/administrator/students/${student.id}`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                navigateTo('/school/students',{state:{from: location},replace:true})
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        }
    }

  return (
    <>
        <button
        type="button"
        onClick={handleShowModal}
        className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Delete Student
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-3 pt-3 text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-center">

                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        DELETE account
                        </Dialog.Title>
                        <div className=" mt-2 text-left font-semibold text-sm">
                            <div className='mb-4'>
                                <p className="text text-red-500">You are about to delete this student and all his/her records, this action is Not reversible</p>
                            </div>
                            <div className='flex '>
                                <div className='w-3/4 grid gap-y-1 ml-5'>
                                    <p>{student?.firstName} {student?.lastName}</p>
                                    <p>{student?.email}</p>
                                    <p>{student?.school?.schoolName}</p>
                                </div>
                                <div className='mx-auto '>
                                    <div className="flex items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon className="h-20 w-20 text-red-600" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <form>
                                    <label className='my-1 text-blue-700'>Type DELETE to proceed</label>
                                    <input 
                                    onChange={handleDeleteConfirm}
                                    className='outline-none	px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 bg-gray-100' />
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="mt-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            disabled={buttonDisabled}
                            className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${buttonDisabled?'bg-gray-500':'bg-red-600 hover:bg-red-500'}`}
                            onClick={handleDelete}>
                            Delete
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef} >
                            Cancel
                        </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    </>
  )
}
