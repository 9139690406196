import React,{useEffect,useContext,useState} from 'react'
import StudentLayout from '../../../components/Layouts/StudentLayout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import {Link, useParams,useNavigate } from "react-router-dom";
import {AuthenticationContext } from '../../../../services/AuthContextModule';
import FavoriteButtonXS from '../../studentComponents/FavoriteButtonXS';
import moment from 'moment';
import { useBanner } from '../../../../services/SystemBannerMessage';
import { useCornerNotifications } from '../../../../services/SystemCornerWarning';

import SeparatorLine from '../../../schoolGeneral/schoolComponents/SeparatorLine';
import { HandRaisedIcon, ClockIcon, MapPinIcon, LockOpenIcon, LockClosedIcon,QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import {CheckCircleIcon,XCircleIcon } from '@heroicons/react/24/solid';
import ProfileAvatar from '../../../components/Avatar';

import Linkify from "linkify-react";
import SkeletonLoader from '../../../pageComponents/SkeletonLoader';

const linkifyOptions = {
  className: 'text-white font-semibold px-2 rounded bg-blue-500 shadow-lg hover:bg-blue-600 whitespace-nowrap', // Tailwind classes for styling the links
  target: '_blank', // Open links in a new tab
  rel: 'noopener noreferrer', // For security reasons
  truncate: 22, // Truncate long links
  format: (value, type) => {
    if (type === "url" && value.length > 10) {
      value = "External Link";
    }
    return value;
  },
};

export default function StudentUccEventDetails(){

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);

  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {eventId} = useParams();

  const navigateTo = useNavigate();
  const {showBanner} = useBanner();
  const {showNotification} = useCornerNotifications();

  const [isLoading, setIsLoading] = useState(true);

  const [eventDetails, setEventDetails] = useState();
  const [gradeLevels, setGradeLevels] = useState([]);
  const [universities, setUniversities] = useState([]);

  const [inviteesTable, setInviteesTable] = useState([]);
  const [invitees, setInvitees] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [declined, setDeclined] = useState([]);
  const [pending, setPending] = useState([]);

  const [statusTabs, setStatusTabs] = useState([
    {active:true, tabName:"INVITED",content:invitees,bgColor:"bg-green-400"},
    {active:false, tabName:"ACCEPTED",content:accepted,bgColor:"bg-green-500"},
    {active:false, tabName:"DECLINED",content:declined,bgColor:"bg-red-500"},
    {active:false, tabName:"PENDING",content:pending,bgColor:"bg-amber-500" }
  ]);

  const [isUserInvited, setIsUserInvited] = useState(false);
  const [hasUserAccepted, setHasUserAccepted] = useState("");

  const checkIsUserInvited = (eventDetails)=>{
    const isInvited = eventDetails.invitations.some((invitation)=>{
        return invitation.invitee_id === userId
        }
    )
    // either invited or not
    setIsUserInvited(isInvited);
}

const checkHasUserAccepted = (eventDetails)=>{
  let hasUserAccepted;
    eventDetails.invitations.forEach((invitation)=>{
      if(invitation.invitee_id === userId){
          if(invitation.status === "accepted"){ 
            hasUserAccepted = "accepted";
          }else if(invitation.status === "declined"){
            hasUserAccepted = "declined";
          } else if(invitation.status === "pending"){
            hasUserAccepted = "pending";
          }
      }
    }
  )
  setHasUserAccepted(hasUserAccepted);
}

  const handleSetContent = (index, statusContent)=>{
    const newActiveTabs = [...statusTabs];
    newActiveTabs[index].content = statusContent;
    setStatusTabs(newActiveTabs);
  }

  const handleInviteeTable = (statusData, index)=>{
    setInviteesTable(statusData);
    const newActiveTabs = [...statusTabs];
    newActiveTabs.forEach((tab)=>{
      tab.active = false;
    });

    newActiveTabs[index].active = true;
    setStatusTabs(newActiveTabs);
  }

  const handleAllInvitees = (statusData)=>{
    setInvitees(statusData);
    handleSetContent(0, statusData);
  }

  const handleAccepted = (statusData)=>{
    const accepted = statusData.filter((invitee)=>invitee.status==="accepted");
    setAccepted(accepted);
    handleSetContent(1, accepted);
  }

  const handleDeclined = (statusData)=>{
    const declined = statusData.filter((invitee)=>invitee.status==="declined");
    setDeclined(declined);
    handleSetContent(2, declined);
  }

  const handlePending = (statusData)=>{
    const pending = statusData.filter((invitee)=>invitee.status==="pending");
    setPending(pending);
    handleSetContent(3, pending);
  }

  const handleUniversities = (visiting_universities)=>{
    const universities = visiting_universities.map((universityData)=>{
      return {
        id: universityData.university.id,
        profilePicture: universityData.university.profilePicture,
        schoolName: universityData.university.schoolName,
        city: universityData.university.city,
        state: universityData.university.state,
        country: universityData.university.country,
        website: universityData.university.website
      }
    })
    setUniversities(universities);
  }

  const getGradeLevels = (eventObject)=>{
    let students = eventObject.invitations.map((invitation)=>{
        return invitation.student
        }
    )
    let gradeLevels = students.map((student)=>{
        return student.gradeLevel
    })
    gradeLevels = gradeLevels.sort((a,b)=>a-b);
    gradeLevels = [...new Set(gradeLevels)];
    setGradeLevels(gradeLevels);
  }

  const checkIsFavorite = (allFavorites,universityId)=>{
      const isFavorite = allFavorites.includes(universityId);
      return isFavorite?<FavoriteButtonXS />:""
  }

    // axios get eventDetails
  const getVisitDetails = async ()=>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/ucc-events/${eventId}`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          setEventDetails(response.data.payload);
          handleUniversities(response.data.payload.visitingUniversities);
          getGradeLevels(response.data.payload);
          handleAllInvitees(response.data.payload.invitations);
          handleAccepted(response.data.payload.invitations);
          handleDeclined(response.data.payload.invitations);
          handlePending(response.data.payload.invitations);

          handleInviteeTable(response.data.payload.invitations, 0);

          checkIsUserInvited(response.data.payload);      // for action buttons
          checkHasUserAccepted(response.data.payload);    // for action buttons

          // const favoritesUniversityId = response.data.payload.findAllFavorites.map((university)=>{
          //    return university.university_id
          // });
          // setAllFavorites(favoritesUniversityId);
        }
      })
      .catch((error)=>{
        console.log(error)
          // navigateTo('/student/ucc-events')
      })
      .finally(()=>{
        setIsLoading(false);
      })
  }

  const handleUserJoin = async ()=>{
    //joining an event, creating new invitation in db
  await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/student/school-events/${eventId}/join`,{},{withCredentials:true})
  .then((response)=>{
    if(response.status === 200){
      getVisitDetails()
    }
    showNotification("You have joined the event","bg-green-400")
  })
  .catch((error)=>{
    console.log("error in joining event",error)
  })
}

const handleAcceptOrDecline = async(e)=>{
    //accept or decline an invitation by toggle true/false in backend;
    await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/student/school-events/${eventId}/respond`,{studentResponse:e.target.value},{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        getVisitDetails();
      }
      if(e.target.value === "accepted"){
        showNotification(`You have accepted the event`,"bg-green-400")
      }else if(e.target.value === "declined"){
        showNotification(`You have declined the event`,"bg-yellow-500")
      }
    })
    .catch((error)=>{
      console.log("error in accepting/declining event",error)
    })
}

const renderActionButtons=()=> {
  const eventTime = moment(eventDetails?.signUpDeadline);
      if(eventTime.isBefore(moment())){
        if(hasUserAccepted === "accepted"){
          return (
            <p className='text-green-500 font-semibold'>ACCEPTED</p>
          );
        }else if(hasUserAccepted === "declined"){
          return (
            <p className='text-red-500 font-semibold'>DECLINED</p>
          );
        }
      }else{
        if (isUserInvited) {
          if (hasUserAccepted === "accepted") {
            return (
              <>
                <button 
                  value="declined" 
                  onClick={handleAcceptOrDecline} 
                  className='transition delay-50 w-44 px-5 py-1 mx-2 font-semibold text-red-500 shadow-sm border-2 border-red-500 hover:bg-red-500 hover:text-white rounded-xl'>DECLINE</button>
                <p className='font-semibold text-sm'>You have <span className='text-green-500'>ACCEPTED</span></p>
              </>
            );
          } else if (hasUserAccepted === "declined") {
            return (
              <>
                <button 
                  value="accepted"
                  onClick={handleAcceptOrDecline} 
                  className='transition delay-50 w-44 px-5 py-1 mx-2 font-semibold text-green-600 shadow-sm border-2 border-green-600 hover:bg-green-600 hover:text-white rounded-xl'>ACCEPT</button>
                <p className='font-semibold text-sm'>You have <span className='text-red-500 '>DECLINED</span></p>
              </>
            );
          } else if (hasUserAccepted === "pending") {
            return (
              <>
                <button 
                  value="accepted"
                  onClick={handleAcceptOrDecline} 
                  className='transition delay-50 w-44  bg-green-500 px-5 py-1 mx-2 font-semibold text-white shadow-sm hover:bg-green-600 rounded'>Accept</button>
                <button 
                  value="declined"
                  onClick={handleAcceptOrDecline} 
                  className='transition delay-50 w-44  bg-red-500 px-5 py-1 mx-2 font-semibold text-white shadow-sm hover:bg-red-600 rounded'>Decline</button>
              </>
            );
          }
        } else {
          if (eventDetails?.openToAll) {
            if (hasUserAccepted === "accepted") {
              return (
                <>
                  <button 
                    value="accepted"
                    onClick={handleAcceptOrDecline} 
                    className='transition delay-50 w-44  bg-red-500 px-5 py-1 mx-2 font-semibold text-white shadow-sm hover:bg-red-600 rounded'>Decline</button>
                  <p className='text-green-500 font-semibold'>ACCEPTED</p>
                </>
              );
            } else if (hasUserAccepted === "declined") {
              return (
                <>
                  <button 
                    value="declined"
                    onClick={handleAcceptOrDecline}
                    className='transition delay-50 w-44  bg-green-500 px-5 py-1 mx-2 font-semibold text-white shadow-sm hover:bg-green-600 rounded'>Accept</button>
                  <p className='text-red-500 font-semibold'>DECLINED</p>
                </>
              );
            } else {
              return (
                <>
                  <button 
                    onClick={handleUserJoin} 
                    className='transition delay-50 w-44  bg-green-500 px-4 py-1 mx-2 font-semibold text-white shadow-sm hover:bg-green-600 rounded'>Join</button>
                </>
                );
              }
            }
          }
      }
  return null;
}

useEffect(()=>{
    getVisitDetails()
},[eventId]);

useEffect(() => {
  if (eventDetails?.eventName) {
    document.title = `${eventDetails.eventName}`;
  }
}, [eventDetails]);

return (
  <StudentLayout>
  <div className='md:w-full w-full'>
    <div className="bg-opacity-75 rounded-lg lg:grid grid-cols-10 gap-x-2 lg:mx-2">
      <div className="col-span-6 lg:min-h-fit rounded-tl-lg lg:mr-3">
      {/* event name */}
        <div className='px-3 py-1 lg:rounded-t-lg grid grid-cols-7 bg-white mb-2 shadow-sm border-b-2 border-indigo-500'>
            <div className='col-span-5 w-full'>
              <div className="text-2xl py-2 text-gray-900 whitespace-normal break-words font-semibold">
                {isLoading?
                  <SkeletonLoader width="full" height="7" style={"bg-indigo-100 rounded-lg"} className="rounded-full"/> 
                  :   // if event name 
                  eventDetails?.eventName
                }
              </div>
            </div>
            <div className='col-span-2 flex justify-end items-center'>
              <p className="font-semibold rounded-full border-2 border-white shadow-sm px-2 bg-slate-100 flex items-center justify-center h-fit whitespace-normal break-words">
                {eventDetails?.eventCategory?.categoryName.toUpperCase()}
              </p>
            </div>
        </div>
      {/* event information  */}
        <div id='event-info-section' className='mb-2 bg-white rounded-xl shadow-sm'>
          <div className="px-3 py-2">
            <div className="grid grid-cols-3 items-center">
              <div className='flex items-center '>
                <ClockIcon className='h-7 w-7 text-sky-500 shadow-sm rounded-full'/>
                <p className='mx-1 text-sm'>Time: </p>
              </div>
              <div className='flex justify-between lg:px-1 items-center col-span-2 text-sm'>
                <div className='font-semibold tracking-wide my-2 text-center rounded-2xl px-2 py-0.5 bg-green-600 text-white w-24 border-2 border-white shadow-sm'>
                  { isLoading?
                    <SkeletonLoader width="16" height="5" style={"bg-green-100 rounded-2xl"} />
                    :   // if loading
                    moment(eventDetails?.eventDateTime).calendar(null,momentShowTime)
                  } 
                  </div>
                  <div className='font-semibold tracking-wide my-1 w-fit rounded-lg text-green-600'>
                  {isLoading?
                    <SkeletonLoader width="44" height="6" style={"bg-green-100 "} />
                    :  // if loading
                    moment(eventDetails?.eventDateTime).calendar(null,momentShowDate)} 
                </div>
              </div>
            </div>
            <SeparatorLine margin={1} />
            <div className='grid grid-cols-3 items-center '>
              <div className='flex items-center '>
                <ClockIcon className='h-7 w-7 text-amber-400 font-semibold shadow-sm rounded-full' />
                <p className='mx-1 text-sm'>
                  End at: </p>
              </div>
              <div className='flex justify-between lg:px-1 items-center col-span-2 text-sm'>
                  <div className='font-semibold tracking-wide my-2 text-center rounded-2xl px-2 py-0.5 bg-amber-400 text-white w-24 border-2 border-white shadow-sm'>
                  { isLoading?
                    <SkeletonLoader width="16" height="5" style={"bg-amber-100 rounded-2xl"} />
                    : // if loaded,show the time
                    moment(eventDetails?.eventEndTime).calendar(null,momentShowTime)}
                  </div>
                  <div className='font-semibold tracking-wide my-2 w-fit rounded-lg'>
                    {isLoading?
                      <SkeletonLoader width="44" height="6" style={"bg-amber-100 "} />
                      : // if loading
                      moment(eventDetails?.eventEndTime).calendar(null,momentShowDate)}
                  </div>
                </div>
            </div>
            <SeparatorLine margin={1} />

            <div className='grid grid-cols-3 items-center '>
              <div className='flex items-center'>
                <HandRaisedIcon className='h-7 w-7 text-red-500 shadow-sm rounded-full' />
                <p className='mx-1 text-sm'>Sign up Deadline: </p>
              </div>
              <div className='flex justify-between lg:px-1 items-center text-sm col-span-2 space-x-2'>
                <div className='font-semibold tracking-wide my-2 text-center rounded-2xl px-2 py-0.5 bg-red-500 text-white w-24 border-2 border-white shadow-sm'>
                {isLoading?
                  <SkeletonLoader width="16" height="5" style={"bg-red-100 rounded-2xl"} />
                  : // if loading
                  moment(eventDetails?.signUpDeadline).calendar(null,momentShowTime)}
                </div>
                <div className='font-semibold tracking-wide my-1 w-fit rounded-lg text-red-500'>
                {isLoading?
                  <SkeletonLoader width="44" height="6" style={"bg-red-100 "} />
                  :   // if loading
                  moment(eventDetails?.signUpDeadline).calendar(null,momentShowDate)}
                </div>
              </div>
            </div>
            <SeparatorLine margin={1} />
            <div className='grid grid-cols-3 items-center '>
              <div className='flex items-center'>
                <MapPinIcon className='h-7 w-7 text-blue-500 shadow-sm rounded-full' />
                <p className='mx-1 text-sm'>Location: </p>
              </div>
              <div className='flex justify-between lg:px-1 items-center col-span-2 text-sm'>
                  <div className='font-semibold my-2 text-center border-2 border-blue-500 rounded-2xl text-blue-500 px-3'>
                  {isLoading?
                    <SkeletonLoader width="16" height="5" style={"bg-slate-100 "} />
                      :   // if loading
                    <Linkify options={linkifyOptions} >{eventDetails?.location}</Linkify>
                  }
                  </div>
                </div>
            </div>
            <SeparatorLine margin={1} />
            <div className='grid grid-cols-3 items-center '>
              <div className='flex items-center'>
              {eventDetails?.openToAll?
                <LockOpenIcon className='h-7 w-7 text-blue-500 ' /> :
                <LockClosedIcon className='h-7 w-7 text-red-500 shadow-sm rounded-full' /> 
              }
                <p className='mx-2 text-sm'>Event Type: </p>
              </div>
              <div className='flex justify-between lg:px-1 items-center col-span-2 text-sm'>
                  {
                    isLoading?
                      <SkeletonLoader width="28" height="6" style={"font-semibold my-2 text-center rounded-2xl text-white px-3 py-1 bg-green-500"} />
                      :   // if open to all
                      eventDetails.openToAll?
                      <p className='font-semibold my-1 text-center rounded-2xl text-white py-0.5 bg-green-500 px-3 border-2 border-white shadow-sm'>OPEN TO ALL</p>
                      :   // if not open to all
                      <p className='font-semibold my-1 w-fit rounded-2xl text-sky-600 py-0.5 px-3 border-2 border-white shadow-sm'>INVITE ONLY</p>
                  }
              </div>
            </div>
            <SeparatorLine margin={1} />
              <p className='text-sm leading-7 text-gray-600 font-semibold'>
                created by: 
                <span className='mx-2 text-blue-700'>{eventDetails?.staff?.firstName +" "+eventDetails?.staff?.lastName}</span>
              </p>
          </div>
        </div>
        <div className='flex items-center justify-between lg:min-h-fit py-1 px-2'>
          {renderActionButtons()}
        </div>

        <div className='lg:min-h-fit shadow-sm bg-white lg:my-0 lg:my-2 rounded-lg mt-2'>
            <p className='px-3 py-2 border-b-2 border-sky-700 rounded-t-lg font-bold text-sky-700'>DETAILS</p>
            <div className='pl-6 pr-3 py-2 text-sm font-semibold whitespace-pre-wrap '>
              <Linkify as='p' options={linkifyOptions}>{eventDetails?.remark}</Linkify>
            </div>
        </div>

        <div className="shadow-sm bg-white mt-4 rounded-lg">
              <p className="py-2 px-3 text-indigo-500 font-semibold rounded-t-lg border-b-2 border-indigo-500">UNIVERSITIES ({universities?.length})
              </p>
              <ul role="list" className="space-y-2 divide-y divide-gray-200 xl:col-span-3 max-h-[500px] overflow-scroll ">
                {
                  universities?.map((university)=>{
                    return (
                      <div key={university?.id} className="flex mx-2 my-1 gap-2 pt-2 sm:flex-row text-sm hover:bg-slate-100">
                        <img
                          className={`object-cover h-10 w-10 inline-block object-cover rounded-full shadow-md border-2 border-teal-50`}
                          src={university?.profilePicture}           
                          alt=""
                        />
                        <div>
                          <p className='font-semibold text-blue-400'>
                              <Link to={`/student/universities/${university?.id}`} className='text-blue-500 hover:text-blue-700'>{university?.schoolName}</Link>
                          </p>
                          <p className='indent-4 text-sm'>{`${university?.city}, ${university?.state ? university?.state :" n/a "}, ${university?.country}`}</p>
                        </div>
                      </div>
                  )})
                }
              </ul>
        </div>
      </div>
      {/* Attendance column */}
      <div id='attendance-section' className='col-span-4 rounded-lg lg:ml-3 relative'>
            <div className='flex justify-between items-center shadow-sm bg-white text-blue-500 border-b-2 border-blue-500 lg:rounded-t-lg'>
              <div className='flex py-0 items-center justify-center'>
                <p className='px-3 py-2 font-semibold'>ATTENDANCE</p>
              </div>
            </div>
            <div className='py-0 shadow-sm mb-2 grid grid-cols-4 bg-white'>
              {
                statusTabs.map((status, index)=>{
                  return (
                    <button 
                      key={index} 
                      onClick={()=>handleInviteeTable(status.content, index)} 
                      className={`transition delay-100 text-center py-2 ${status.active?status.bgColor:""}`}>
                      <p className='text-2xl '>{status.content.length}</p>
                      <p className={`text-xs font-semibold text-slate-400 ${status.active?"text-white":""} `}>{status.tabName}</p>
                    </button>
                  )
                })
              }
            </div>
            <div className='py-0 shadow-sm mb-2 bg-white max-h-[560px] overflow-auto relative rounded-lg'>
              <table id='inviteesTable' className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr className='sticky top-0 grid grid-cols-5 py-2 border-b-2'>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0 col-span-3 px-3">Name</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0 text-center">Grade</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0 text-center">Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-300'>
                  {
                    inviteesTable.map((invitation)=>{
                      return (
                        <tr key={invitation?.id} className='hover:bg-gray-100 grid grid-cols-5'> 
                          <td className='whitespace-nowrap text-sm text-blue-600 font-semibold py-2 pl-2 col-span-3'>
                            <div className='flex items-center gap-1'>
                              <ProfileAvatar user={invitation?.student} size={8} textSize={'text-xs'} />
                              {invitation?.student.firstName} {invitation?.student.lastName}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-2 text-sm text-center '>{invitation?.student.gradeLevel}</td>
                          <td className='whitespace-nowrap px-3 py-1 text-xs text-center '>
                            <div 
                              className="flex justify-center relative group">
                              {
                                invitation?.status ==="accepted"?
                                <>
                                  <CheckCircleIcon className='h-6 w-6 text-green-500'/>
                                  <div className="absolute bottom-full mb-2 hidden group-hover:block px-2 py-1 bg-gray-800 text-white text-sm rounded">
                                    Accepted
                                  </div>
                                </>
                                : invitation?.status==="declined"?
                                <>
                                  <XCircleIcon className='h-6 w-6 text-red-500'/>
                                  <div className="absolute bottom-full mb-2 hidden group-hover:block px-2 py-1 bg-gray-800 text-white text-sm rounded">
                                    Declined
                                  </div>
                                </>
                                :
                                <>
                                  <QuestionMarkCircleIcon className='h-6 w-6 text-orange-500'/>
                                  <div className="absolute bottom-full mb-2 hidden group-hover:block px-2 py-1 bg-gray-800 text-white text-sm rounded">
                                    Pending
                                  </div>
                                </>
                              }
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
      </div>
        {/* university and attendance section  */}
      <div className="mx-auto ">
      </div>
      {/* --------------------------------------------- */}
      <div className='flex flex-col lg:flex-row gap-2 mt-3 items-center'>
          {/* <button 
            type='button' 
            value="accepted" 
            // onClick={handleChangeStatus} 
            className={`w-full lg:w-1/3 rounded px-3 py-2 text-xs font-bold text-white shadow-sm ${acceptStatus ==="accepted"?"bg-gray-300":"bg-green-500 hover:bg-green-600"}`}
            disabled={acceptStatus ==="accepted"?true:null} >ACCEPT</button>
            <button 
            type='button' 
            value="declined" 
            // onClick={handleChangeStatus} 
            className={`w-full lg:w-1/3 rounded px-3 py-2 text-xs font-bold text-white shadow-sm ${acceptStatus ==="declined"?"bg-gray-300":"bg-red-600 hover:bg-red-600"}`}
            disabled={acceptStatus ==="declined"?true:null} 
            >DECLINE</button> */}
      </div>
      {/* --------------------------------------------- */}
       
      </div>
    </div>
  </StudentLayout>
  )
}

const momentShowTime = {
  sameDay: 'LT', // Today (Monday) at 2:30 PM
  nextDay: 'LT', // Tomorrow (Tuesday) at 2:30 PM
  nextWeek: 'LT', // Sunday, 09/20/2020 at 2:30 PM
  lastDay: 'LT', // Yesterday (Sunday) at 2:30 PM
  lastWeek: 'LT', // Last Monday, 09/14/2020 at 2:30 PM
  sameElse: 'LT' // 09/14/2020 (Friday) at 2:30 PM
};
const momentShowDate = {
  sameDay: '[Today] (dddd)', // Today (Monday)
  nextDay: '[Tomorrow] (dddd)', // Tomorrow (Tuesday)
  nextWeek: 'MMM DD, YYYY (dddd)', // Sunday, 09/20/2020
  lastDay: '[Yesterday] (dddd)', // Yesterday (Sunday)
  lastWeek: '[Last] dddd, MMM DD, YYYY', // Last Monday, 09/14/2020
  sameElse: 'MMM DD, YYYY (dddd)' // 09/14/2020 (Friday)
};