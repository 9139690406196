import React, { useContext, useEffect, useState } from 'react'
import StaffLayout from '../../components/Layouts/StaffLayout';

import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { Link } from 'react-router-dom';
import { delayLoading } from '../../../utils/loadingDelay';
import SkeletonTableRow from '../../pageComponents/SkeletonTableRow';

import ProfileAvatar from '../../components/Avatar';

export default function StudentsAll() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();

  const userId = authState.user;
  const schoolId = authState.schoolId;
  const userRole = authState.role;

  const [isPageLoading,setIsPageLoading] = useState(true);

  const [allStudents, setAllStudents] = useState([]);
  const [studentsTable,setStudentsTable] = useState([]);
  const [studentsTabs,setStudentsTabs]= useState([
      {grade:"all",active:false,students:[]},
      // {grade:"4",active:false,students:[]},
      // {grade:"5",active:false,students:[]},
      {grade:"6",active:false,students:[]},
      {grade:"7",active:false,students:[]},
      {grade:"8",active:false,students:[]},
      {grade:"9",active:false,students:[]},
      {grade:"10",active:false,students:[]},
      {grade:"11",active:false,students:[]},
      {grade:"12",active:false,students:[]}
    ])

  const handleSetStudentsTab = (index,gradeLevel)=>{
      setSearchValue("");
      const refreshedData = [...studentsTabs];
      refreshedData.forEach((tab)=>{
        tab.active = false;
        tab.students = allStudents.filter((student)=>{
          if(tab.grade === "all"){
            return student
          }
          return student.gradeLevel === parseInt(tab.grade);
        })
      });
      refreshedData[index].active = true;
      setStudentsTabs(refreshedData);
      handleSetStudentsTable(refreshedData[index].students,gradeLevel)
  }

  const initialHandleStudents = (studentsData)=>{
    const refreshedData = [...studentsTabs];
    refreshedData.forEach((tab)=>{
      tab.active = false;
      tab.students = studentsData.filter((student)=>{
        if(tab.grade === "all"){
          return student
        }
        return student.gradeLevel === parseInt(tab.grade);
      })
    });
  }

  const handleSetStudentsTable = (studentsData)=>{
      setStudentsTable(studentsData)
  }
  
  const [searchValue,setSearchValue] = useState("");

  const handleSearch =(e)=>{
    setSearchValue(e.target.value);
    let filteredList = allStudents.filter((student)=>{
      const searchKeys = ["firstName","lastName","email"];
      return searchKeys.some((key)=>{
        if(typeof student[key] ==="string" && student[key].toLowerCase().includes(e.target.value.toLocaleLowerCase())){
          return student
        }
      });
    });

    handleSetStudentsTable(filteredList);
    const refreshedData = [...studentsTabs];
    refreshedData.forEach((tab)=>{
      tab.active = false;
    });
    refreshedData[0].active = true;
    setStudentsTabs(refreshedData);
  }

  const getAllStudents = async() =>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/students`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          let sortedStudents = response.data.payload.sort((a,b)=>{
            return b.gradeLevel - a.gradeLevel;
          });
          // steps here to link each invitation to the student

          let invitations = response.data.invitations;
          let students = sortedStudents;
          let newSortedStudents = students.map((student)=>{
            let studentInvitations = invitations.filter((invitation)=>{
              return student.id === invitation.invitee_id;
            });
            if(studentInvitations.length > 0){
              student.invitations = studentInvitations;
            }
            return student;
          });
          setAllStudents(newSortedStudents);
          setStudentsTable(newSortedStudents);
          initialHandleStudents(newSortedStudents);
        }
      })
      .catch((error)=>{
        console.log(error)
      })
      .finally(()=>{
        setIsPageLoading(false);
      })
  }

  useEffect(()=>{
    document.title = "Students";
    delayLoading(getAllStudents,2000)    // for testing skeleton loading
    getAllStudents();
  },[])

  return (
    <>
    <StaffLayout>
      <div className='md:w-full w-full'>
{/* overall stats */}
        <div className='bg-opacity-75 px-1 py-2 lg:mx-2'>
          <div className='overflow-x-auto shadow-sm rounded-2xl'>
            <div className="grid grid-cols-2 xs:grid-cols-2 lg:grid-cols-4">
              {
                studentsTabs.map((tab,index)=>{
                return (
                  <button 
                    key={index}
                    className={`transition delay-100 flex items-baseline flex-wrap justify-between gap-x-4 border-t border-gray-900/5 px-4 py-1 sm:px-6 lg:border-t-0 xl:px-8 hover:cursor-pointer ${index !==studentsTabs.length-1 ? "lg:border-r":""} ${tab.active?"bg-indigo-500 text-white":"bg-white"}`}
                    onClick={()=>{handleSetStudentsTab(index,tab.grade)}}>
                      <p className={`w-full text-sm font-medium leading-6 text-gray-500 ${tab.active?"text-white":"text-gray-500"}`}>Grade {tab.grade}</p>
                      <p className="w-full flex-none text-3xl font-medium leading-10 tracking-tight ">
                        {tab.students.length}
                      </p>
                  </button>
                )})
              }
            </div>
          </div>

          <div className='px-0 sm:px-6 lg:px-8 sm:flex sm:items-center my-0 gap-x-2'>
            <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5 py-2 '>
              <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Student</p>
              <input 
                value={searchValue}
                onChange={handleSearch}
                placeholder='find student' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
            <div className='lg:w-1/5 flex justify-center lg:justify-end '>
              {
                userRole.includes("administrator")? 
                  <Link to={`/school/administrator/students/onboard`} >
                      <button className='transition ease delay-100 bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-5 rounded-2xl text-sm'>Onboard</button>
                  </Link> : null
              }
            </div>
          </div>

          <div className="rounded-2xl shadow-sm overflow-auto max-h-160 relative">
            <table className='min-w-full divide-y divide-gray-300' >
              <thead className='bg-indigo-500 text-white '>
                <tr className='text-center'>
                  <th scope="col" className="sticky top-0 py-2 px-3 text-xs font-semibold sm:pl-6 w-1/7">Name</th>
                  <th scope="col" className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Grade</th>
                  <th scope="col" className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Email</th>
                  <th scope="col"  className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Invited</th>
                  <th scope="col"  className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Accepted</th>
                  <th scope="col"  className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Declined</th>
                  <th scope="col"  className="sticky top-0 px-3 py-2 text-xs font-semibold w-1/7">Activated</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-300 bg-white'>
                {
                  isPageLoading?
                  <SkeletonTableRow colNumber={5} rowNumber={11} />
                  :     // after page loading
                  studentsTable.map((student)=>{
                    return (
                      <tr key={student.id} className='hover:bg-gray-100 text-center' >
                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm text-blue-600 font-semibold w-1/6'>
                          <div className='flex space-x-2 items-center'>
                          <ProfileAvatar user={student} size={8} textSize={'text-xs'}/>
                          <Link to={`/school/students/${student?.id}`} >
                          {student?.firstName} {student?.lastName}
                          </Link>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 font-semibold w-1/7'>
                          <p className='text-white font-semibold p-1 rounded-full bg-indigo-500 w-8 h-8 flex justify-center items-center mx-auto border-2 border-slate-300 shadow-sm'>{student.gradeLevel}
                          </p>
                        </td>
                        <td className='whitespace-nowrap px-1 py-3 text-sm w-1/7 break-words'>{student.email}</td>
                        <td className=' w-1/7 break-words items-center'>
                         <p className='text-white font-semibold p-1 rounded-full bg-blue-500 w-8 h-8 flex justify-center items-center mx-auto border-2 border-zinc-200 shadow-sm'>{student.invitations? student.invitations.length
                          :0}</p>
                        </td>
                        <td className='w-1/7 break-words text-green-500 font-semibold'>
                          <p className='text-white font-semibold p-1 rounded-full bg-green-500 w-8 h-8 flex justify-center items-center mx-auto border-2 border-cyan-200 shadow-sm'>
                            {student.invitations? student.invitations.filter((invitation)=>{
                                return invitation.status === "accepted"
                              }).length
                              :0}
                          </p>
                        </td>
                        <td className='w-1/7 break-words text-red-500 font-semibold'>
                          <p className='text-white font-semibold p-1 rounded-full bg-red-500 w-8 h-8 flex justify-center items-center mx-auto border-2 border-slate-300 shadow-sm'>
                          {student.invitations? student.invitations.filter((invitation)=>{
                              return invitation.status === "declined"
                            }).length
                            :0}
                          </p>
                        </td>

                        <td className={`text-xs whitespace-nowrap font-semibold w-1/6`}>
                          <p className={`mx-5 px-1 py-1 shadow-sm rounded ${student.activated?"bg-green-500 text-white":"border-1 border-amber-500"}`}>
                          {student.activated?"Active":"Inactive"}
                          </p>
                          </td>
                      </tr>
                    )})
                }
              </tbody>
            </table>
          </div>
          </div>
        </div>
     
    </StaffLayout>
    </>
  )
}
